import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content';
import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';

const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/betting-exchange/`,
                    "name": "Bet on Sports",
                    "position": 2
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/betting-exchange/football-betting`,
                    "name": "Online Football ⚽ Betting",
                    "position": 3
                }],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

const FootballSEO = (props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.co';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.net';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
      }

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7':  'Betdaily' ;


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }

                </script>
            </Helmet>
            {brandId == 33 ? <></> :
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>Football Betting</h1></label>
                    <div className="accordion__content">

                        <h2> Football Betting in India</h2>

                        <p>Despite the popularity of cricket in India, football is another sport that has left its mark on Indian sports fans. Due to pop culture exposure and access to social media, football is emerging as a modern sport among youngsters and millennials in India.

                            The demand for national and international football leagues has been rising. It is the same for football betting as well. Football is counted among the top three most watched sports in India along with cricket and kabaddi.
                        </p>
                        <h2> Betting on Football in India</h2>

                        <p>Although the game has always been liked in certain states and regions of India, here we are talking about the national statistics. As per a recent survey, 45% of the Indians living in metropolitan cities are enthusiastic about the sport and online football betting.

                            Kerala as a state is too fascinated about football, but Hyderabad and Telangana top the football betting market in India.
                        </p>
                        <h2> Popular tournaments & leagues</h2>

                        <p> There are so many choices of football leagues to bet on. These could easily overwhelm and confuse a punter.

                            On <a href={`${domainURL}`}>{domain}</a>, we have ample options for football betting that could be used for guidance by any player. Aside from your interests in local or international competitions, {domain} offers to bet on the following leagues and matches:

                            FIFA World Cup
                            English Premier League
                            Indian Super League
                            Spanish La Liga
                            German Bundesliga
                            Italian Serie A
                            I-League
                            Major League Football
                            Portuguese Primeira Liga
                            Dutch Eredivisie
                            Chinese Super League
                            And many more to choose from.
                            Consider football the best sport to experience the actual thrill of online betting. We bring the choices, and you get the bets!
                        </p>
                        <h2> Rules, Strategies and Tips</h2>

                        <p>  Following a good betting strategy could be the key to winning some real cash while betting on football in India.

                            Professional bettors advise to research about the teams, player form & performance could be easily impactful in changing the results of a well-predicted league.
                            Only a few punters go into the depths of teams before betting on football bets. This is the primary reason why many bettors lose money or we can say why some punters are called lucky.
                            Stick to some strategies before you start to wager on football. These could be anything like studying the rules, players or markets.
                            No knowledge of the sport while betting could be the reason you end up with a loss.</p>
                        <h2> Football betting odds | Importance of odds in football</h2>

                        <p>  The odds of football betting depend on your research and study about the sport. {domain} can be your reliable football prediction site to assist you in choosing your odds.

                            We have the latest updates, player history, team performance, weather information, etc. We could be your know-it-all guide for anything related to football betting.</p>

                        <h2> What makes {domain} the best site for football betting in India?</h2>

                        <p> Regular offers and bonuses always welcome you at {domain}, the best football prediction site.

                            Exciting offers & bonuses - These offers apply when you are signing up on our website and whenever you make a redeposit. What could be more rewarding than this!
                            Smoother interface - We also provide an amazing user experience while betting. Our website is user-friendly and interactive to keep you entertained.
                            <a href={`${domainURL}/app-download`}>App version</a> - Get involved in an enjoyable betting experience on our website or at the tap of your finger with our engaging mobile app.
                            Safer betting - We are a safe and secure online betting website trusted by many.
                            Various betting options for football are available.
                            Simple process to register. Faster withdrawal support. 24x7 customer assistance.
                            Now all that is left is for you to <a href={`${domainURL}/signup`}>sign up</a> and choose your game.</p>

                        <h2> How to bet on football in India?</h2>

                        <p> To bet on football leagues, all players should be aware of the kind of bets they are allowed to place. This would also help you determine the success of your football betting in India. Some kinds of bets are the most common ones placed widely around the world.
                        </p>
                        <h3> Football full-time Match Bet</h3>

                        <p> The player picks a team that would win the match, and that team takes the wager in this bet.
                        </p>
                        <h3> Player-wise Bet</h3>

                        <p> This involves predicting the player who will become the man of the match.
                        </p>
                        <h3> Futures Bet</h3>

                        <p> It’s a long-term bet placed on the team that will win the championship.
                        </p>
                        <h3> Full-time Goal Bet</h3>

                        <p> This is a bet placed on the possible total number of goals during a football game. The bet is placed on the over/under.
                        </p>
                        <h2> Wrapping up & interesting facts about football betting</h2>
                        <p>
                            Every player wants to excel at football betting. Learning about the sport is important to see a profitable growth in your betting curve.

                            We recommend you do your research well. To build your interest in football, we have lined up some fun facts.</p>
                        <ul>
                            <li>Each player runs an average of 9.65 km during a football match. (You don’t need to run and sweat. Download the app and begin your football betting journey.)</li>
                            <li>Football is the only popular sport where it's allowed to bet on a draw.</li>
                            <li>During the 1st football World Cup finals, only 300 people were present in the stadium. (The number of people who betted on that match could be more. Who knows!)
                            </li>
                            <li> The first basketball match was played with a football. (Shocking right? But we have a separate section for Basketball betting)
                            </li>
                        </ul>
                        <p> The maximum number of goals scored by a single player in a football match is 16. (What a chance! If someone betted on that player that day!)
                            Start betting on football with {domain}. With 24x7 customer service and a very minimum wagering requirements, live football betting options are now open for betting. Create and account and get more than what other betting sites offer: better gaming & enriching betting experience.</p>
                    </div>

                </Accordion>
            </AccordionWrapper>
            }
        </>
    )
}

export default withAppContext(FootballSEO);